import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, navigate } from "gatsby";
import { Header } from "../components/Header/Header";
import logoWhite from "../images/logo-white.png";
import LoaderIcon from "../images/loader.svg";

import {
  CircleStackIcon,
  UserPlusIcon,
  MagnifyingGlassCircleIcon,
  AdjustmentsVerticalIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { getUsers, searchUsers } from "../helpers/courses";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

const Students = () => {
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const [signed, setSigned] = useState(false);
  const [students, setStudents] = useState<any>(null);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [mailOwn, setMail] = useState("");

  const [loading, setLoading] = useState(true);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (loginElement.items.status) {
      setMail(loginElement.items.data._embedded.user.profile.login);
      setSigned(true);
    } else {
      navigate("/");
    }
  }, [loginElement]);

  const getStudents = async () => {
    setLoading(true);
    const users = await getUsers();
    setStudents(users?.filter((item: any) => item.profile.login !== mailOwn));
    setLoading(false);
  };

  const searchingStudents = async () => {
    setLoading(true);
    const filteredUsers = await searchUsers(name, status);
    setStudents(
      filteredUsers.filter((item: any) => item.profile.login !== mailOwn)
    );
    setLoading(false);
  };

  const onChangeValue = (event: any) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (name === "" && status === "") {
        getStudents();
      } else {
        searchingStudents();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [name, status]);

  return (
    <Layout signed={signed}>
      <div className="bg-[#f2f2f2]">
        <div className="mb-[40px]">
          {/* header */}
          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="lg:flex lg:items-center lg:justify-between pb-10">
              <div className="flex items-center">
                <img src="" alt="" />
                <div className="mb-4 md:mb-0">
                  <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                    Students
                  </h2>
                </div>
              </div>
              <div className="md:flex items-center gap-4">
                <button className="mb-4 md:mb-0 w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                  <CircleStackIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px]">Export CSV</span>
                </button>
                <Link
                  to="/add-student"
                  className="mb-4 md:mb-0 w-full lg:w-fit flex items-center justify-between border border-solid border-[#fdbf38] bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl"
                >
                  <UserPlusIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px]">
                    Add Students
                  </span>
                </Link>
              </div>
            </div>
            <div className="lg:flex lg:items-center gap-8 pb-10">
              <div className="shadow-lg flex items-center px-[14px] py-[7px] rounded-2xl w-full cursor-pointer bg-white mb-4 md:mb-0">
                <MagnifyingGlassCircleIcon className="h-6 w-6 text-[#da1a32] mr-[15px]" />
                <input
                  className="w-full ff-cg--semibold placeholder:text-[#000000] p-[10px] focus:outline-none"
                  type="search"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Search Students"
                />
              </div>
              <div className="relative filter-parent pb-2">
                <button className="w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                  <AdjustmentsVerticalIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">
                    Filter By
                  </span>
                </button>
                <ul
                  onChange={onChangeValue}
                  className="absolute bg-white rounded-xl overflow-hidden p-4 border border-black mt-0 w-60 right-0 filter-options top-full"
                >
                  <li className="font-bold text-2xl mb-3">Status</li>
                  <li className="cursor-pointer mb-2">
                    <label>
                      <input
                        type="radio"
                        name="status"
                        value=""
                        checked={status === ""}
                      />
                      <span className="ml-2">All</span>
                    </label>
                  </li>
                  <li className="cursor-pointer mb-2">
                    <label>
                      <input
                        type="radio"
                        name="status"
                        value="ACTIVE"
                        checked={status === "ACTIVE"}
                      />
                      <span className="ml-2">Active</span>
                    </label>
                  </li>
                  <li className="cursor-pointer mb-2">
                    <label>
                      <input
                        type="radio"
                        name="status"
                        value="INACTIVE"
                        checked={status === "INACTIVE"}
                      />
                      <span className="ml-2">Inactive</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
              <div className="col-span-12">
                <div className="rounded-xl bg-white shadow-lg h-full p-[3px]">
                  <div className="overflow-x-auto">
                    {students !== null ? (
                      <table className="lg:w-full rounded-2xl">
                        <thead className="text-white rounded-2xl  border-[#da1a32]">
                          <tr>
                            <th className="bg-[#da1a32] rounded-tl-xl text-left ff-cg--semibold py-4 px-8">
                              Name
                            </th>
                            <th className="bg-[#da1a32] text-left ff-cg--semibold py-4 px-8">
                              Email
                            </th>
                            {/* <th className="bg-[#da1a32] text-left ff-cg--semibold py-4 px-8">Enrollments</th> */}
                            <th className="bg-[#da1a32] text-left ff-cg--semibold py-4 px-8">
                              Status
                            </th>
                            <th className="bg-[#da1a32] rounded-tr-xl text-right ff-cg--semibold py-4 px-8"></th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <>
                            {students?.map((item: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="py-4 px-8">
                                    {item.profile.firstName}{" "}
                                    {item.profile.lastName}
                                  </td>
                                  <td className="py-4 px-8">
                                    {item.profile.login}
                                  </td>
                                  {/* <td className="py-4 px-8">3 Courses</td> */}
                                  <td className="py-4 px-8">{item.status}</td>
                                  <td className="py-4 px-8">
                                    <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                                      <Link
                                        state={{ user: item }}
                                        to="/edit-student"
                                        className="flex"
                                      >
                                        <PencilSquareIcon className="h-6 w-6" />
                                        <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">
                                          Edit
                                        </span>
                                      </Link>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                          {/* <tr className="border-b border-solid">
                          <td className="py-4 px-8">John Doe</td>
                          <td className="py-4 px-8">john@microsoft.com</td>
                          <td className="py-4 px-8">3 Courses</td>
                          <td className="py-4 px-8">Active</td>
                          <td className="py-4 px-8">
                            <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                              <Link to="/edit-student" className="flex"><PencilSquareIcon className="h-6 w-6"/>
                              <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">Edit</span></Link>
                            </button>
                          </td>
                        </tr>
                        <tr className="border-b border-solid">
                          <td className="py-4 px-8">John Doe</td>
                          <td className="py-4 px-8">john@microsoft.com</td>
                          <td className="py-4 px-8">3 Courses</td>
                          <td className="py-4 px-8">Active</td>
                          <td className="py-4 px-8">
                            <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                              <Link to="/edit-student" className="flex"><PencilSquareIcon className="h-6 w-6"/>
                              <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">Edit</span></Link>
                            </button>
                          </td>
                        </tr>
                        <tr className="border-b border-solid">
                          <td className="py-4 px-8">John Doe</td>
                          <td className="py-4 px-8">john@microsoft.com</td>
                          <td className="py-4 px-8">3 Courses</td>
                          <td className="py-4 px-8">Active</td>
                          <td className="py-4 px-8">
                            <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                              <Link to="/edit-student" className="flex"><PencilSquareIcon className="h-6 w-6"/>
                              <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">Edit</span></Link>
                            </button>
                          </td>
                        </tr> */}
                        </tbody>
                      </table>
                    ) : (
                      <div className="w-full h-full flex justify-center pt-20 pb-20">
                        <div className="text-center">
                          {loading ? (
                            <>
                              <img
                                src={LoaderIcon}
                                className={`w-[120px] h-[120px] mmb-4 inline-block`}
                              />
                              <p className="font-bold text-4xl mb-2 ff-cg--semibold">
                                Loading Results
                              </p>
                              <p className="text-xl">
                                Please wait a moment while we set things up for
                                you!
                              </p>
                            </>
                          ) : (
                            <p className="text-xl">
                              Theres no results for your search
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="flex items-center justify-between py-4 px-8">
                    <p className="ff-cg--semibold">Showing 1 - 5 of 10 Items</p>
                    <div className="flex items-center gap-4">
                      <button className="rounded-md flex items-center justify-center text-[18px] bg-[#da1a32] text-white w-10 h-10">
                        <span className="ff-cg--semibold">1</span>
                      </button>
                      <button className="rounded-md flex items-center justify-center text-[18px] border-2 border-solid border-[#da1a32] text-[#da1a32] w-10 h-10">
                        <span className="ff-cg--semibold">2</span>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Students;
export { Head } from "../components/Layout/Head";
